.navbar-brand {
	margin-right: 0;
	margin-left: 0.5rem;
}



@each $breakpoint in map-keys($grid-breakpoints) {
	@each $breakpoint in map-keys($grid-breakpoints) {
		$infix: breakpoint-infix($breakpoint, $grid-breakpoints);

		@include media-breakpoint-up($breakpoint, $grid-breakpoints) {
		// `$grid-columns - 1` because offsetting by the width of an entire row isn't possible
		@for $i from 0 through ($grid-columns - 1) {
			@if not ($infix == "" and $i == 0) { // Avoid emitting useless .offset-0
			.offset#{$infix}-#{$i} {
				$num: $i / $grid-columns;
				margin-left: none;
				margin-right: if($num == 0, 0, percentage($num));
			}
			}
		}
		}
	}
}


.pagination{
	padding-right: 0;
}


.page-item {
  &:first-child {
    .page-link {
      margin-left: 0;
      @include border-right-radius($border-radius);
      @include border-left-radius(0);
    }
  }
  &:last-child {
    .page-link {
	  @include border-left-radius($border-radius);
	  @include border-right-radius(0);
    }
  }
}