.post{
    .post-meta {
        padding: 0.5rem 0.7rem;

        .socials{
            padding: 0;
            margin: 0;

            > li {
                display: inline-block;
                > a {
                    background-color: $primary;
                    color: #fff;
                    border-radius: 5px;
                    display: block;
                    // font-size: 1.1rem;
                    height: 1.2rem;
                    padding: 0.1rem;
                    text-align: center;
                    width: 1.2rem;
                }
            }
        }
    }

    .post-nav{
        margin: 1rem 0;
        padding: 1rem 0;
        border-top: 2px solid $border-color;
        border-bottom: 2px solid $border-color;

        .prev{
            padding-right: 0;
        }

        .next{
            padding-left: 0;
        }
    }

    h1, h2{
        a{
            color: $headings-color;
            &:hover{
                color: $link-color;
            }
        }
    }

    .content{
        ul{
            list-style: none;
            li{
                margin-bottom: 1rem;
                
                p{
                    display: inline;
                    margin: 0;
                }
                &:before{
                    content: "—";
                    display: inline-block;
                    width: 1.5rem;
                    margin-right: -1.5rem;
                }
            }
        }
    }

    .modify-date{
        font-style: italic;
        color: darken($warning, 20%);
        font-size: 0.7rem;
    }
}