@font-face {
    font-family: 'Samim';
    src: url('/assets/fonts/Samim.eot');
    src: url('/assets/fonts/Samim.eot?#iefix') format('embedded-opentype'),
		 url('/assets/fonts/Samim.woff') format('woff'),
		 url('/assets/fonts/Samim.ttf') format('truetype');
    font-weight: normal;
}

@font-face {
    font-family: 'Samim';
    src: url('/assets/fonts/Samim-Bold.eot');
    src: url('/assets/fonts/Samim-Bold.eot?#iefix') format('embedded-opentype'),
		 url('/assets/fonts/Samim-Bold.woff') format('woff'),
		 url('/assets/fonts/Samim-Bold.ttf') format('truetype');
    font-weight: bold;
}

*{
    direction: rtl;
    text-align: right;
}

html, body{
    font-size: 14px;
}

a:hover{
    text-decoration: none;
}

h2, .h2, h3, .h3{
    margin-top: $spacer * 2;
}

blockquote{
    background-color: darken($body-bg, 2%);
    border: 2px dashed darken($body-bg, 5%);
    padding: 1rem;
    border-radius: 0.3rem;
    font-style: italic;
    color: lighten($body-color, 10%);
}

article{
    h2{
        margin-top: 3.5rem;
    }
    h2, h3{
        font-weight: normal;
    }

    img{
        display: block;
        max-width: 100%;
        margin: 1rem 0;
        border: 1px solid $light;
    }

    @include media-breakpoint-between(sm, xl){
        img{
            float: left;
            max-width: 50%;
            margin: 0 1rem 1rem 0;
        }
    }
}