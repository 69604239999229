.navbar{
    a{
        color: inherit;
        &:hover{
            color: $link-color;
        }
    }

    .nav{
        color: $gray-600;
        &:before{
            content: "\2014";
            padding-left: 0.5rem;
        }

        .nav-divider:after{
            content: "/";
            padding: 0 0.2rem;
        }
    }

    .nav-socials{
        color: $text-muted;
        font-size: 1.2rem;
        span{
            font-size: 0.7rem;
        }
    }
}

.sharect{
    background-color: $primary !important;
    > div:last-child{
        border-top-color: $primary !important;
    }
}