@import "../node_modules/bootstrap/scss/functions";
@import "minima/variables";

@import "../node_modules/@fortawesome/fontawesome-free/scss/fontawesome";
@import "../node_modules/@fortawesome/fontawesome-free/scss/solid";
@import "../node_modules/@fortawesome/fontawesome-free/scss/brands";

@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";
@import "../node_modules/bootstrap/scss/root";
@import "../node_modules/bootstrap/scss/reboot";
@import "../node_modules/bootstrap/scss/type";
@import "../node_modules/bootstrap/scss/grid";
@import "../node_modules/bootstrap/scss/navbar";
@import "../node_modules/bootstrap/scss/pagination";

@import "../node_modules/bootstrap/scss/utilities/background";
@import "../node_modules/bootstrap/scss/utilities/borders";
@import "../node_modules/bootstrap/scss/utilities/display";
@import "../node_modules/bootstrap/scss/utilities/flex";
@import "../node_modules/bootstrap/scss/utilities/position";
@import "../node_modules/bootstrap/scss/utilities/text";
@import "../node_modules/bootstrap/scss/utilities/spacing";

@import "minima/rtl";
@import "minima/type";
@import "minima/layout";
@import "minima/post";